<template>
  <div class="jiesuan">
    <el-row :gutter="2">
      <el-col :span="2"><div class="grid-content">结算金额:</div></el-col>
      <el-col :span="22">
        <div class="grid-content">{{ jiesuan.amount }}</div>
      </el-col>
    </el-row>
    <el-row :gutter="2">
      <el-col :span="2"><div class="grid-content">结算单:</div></el-col>
      <el-col :span="22">
        <div class="grid-content">
          <el-button type="success" size="mini" @click="handlePhotos()" v-if="userType.jiesuan_jiesuandan === true && !jiesuan.amount === false"
            >查看</el-button
          >
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="2">
      <el-col :span="2"><div class="grid-content-high">合同结算说明:</div></el-col>
      <el-col :span="22">
        <div class="grid-content-high" v-if="userType.jiesuan_jiesuandan === true && !jiesuan.amount === false">
          {{ jiesuan.added_person_time }} ({{ jiesuan.added_person }}) <br />
          {{ jiesuan.remark }}
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props: {
    jiesuan: Object,
    userType: Object
  },
  methods: {
    //显示图片
    handlePhotos(contract_id) {
      this.$emit('showPhotos', contract_id)
    }
  }
}
</script>
